import {
  ColumnDef,
  Column,
  formatDate,
  formatMoney,
  formatNumber,
  formatSize,
  formatPercentage,
} from '@/components/helpers/Column';
import { UserRolesEnum } from '@mdb/core';
/**
 * Property propertyColumnsDef details
 */
enum PropertyColumnEnum {
  id = '_id',
  createdAt = 'createdAt',
  createdBy = 'createdBy',
  rentability = 'rentability',
  street = 'street',
  additionnalDetails = 'additionnalDetails',
  postalCode = 'postalCode',
  city = 'city',
  country = 'country',
  size = 'size',
  buyingPriceNet = 'buyingPriceNet',
  buyingPriceAgencyFees = 'buyingPriceAgencyFees',
  type = 'type',
  rentalAmount = 'rentalAmount',
  comment = 'comment',
  individualElectricMeter = 'individualElectricMeter',
  serviced = 'service',
  sewage = 'sewage',
  groundPriceNet = 'groundPriceNet',
  groundAgencyFees = 'groundAgencyFees',
  groundSize = 'groundAgencySize',
  detailsSize = 'detailsSize',
  resalePriceHigh = 'resalePriceHigh',
  resalePriceMedium = 'resalePriceMedium',
  resalePriceLow = 'resalePriceLow',
  computedPriceHigh = 'computedPriceHigh',
  computedPriceMedium = 'computedPriceMedium',
  computedPriceLow = 'computedPriceLow',
  detailsPriceNet = 'detailsPriceNet',
  detailsAgencyFeesLow = 'detailsAgencyFeesLow',
  detailsAgencyFeesMedium = 'detailsAgencyFeesMedium',
  detailsAgencyFeesHigh = 'detailsAgencyFeesHigh',
  cutSize = 'cutSize',
}
const propertyColumnsDef: Map<String, ColumnDef> = new Map();
propertyColumnsDef.set(
  PropertyColumnEnum.id,
  new ColumnDef({
    id: PropertyColumnEnum.id,
    key: '_id',
    label: 'common.id',
  })
);

propertyColumnsDef.set(
  PropertyColumnEnum.createdAt,
  new ColumnDef({
    id: PropertyColumnEnum.createdAt,
    key: 'createdAt',
    label: 'common.created_at',
    format: formatDate,
  })
);

propertyColumnsDef.set(
  PropertyColumnEnum.street,
  new ColumnDef({
    id: PropertyColumnEnum.street,
    key: 'address.street',
    label: 'property.form.address',
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.additionnalDetails,
  new ColumnDef({
    id: PropertyColumnEnum.additionnalDetails,
    key: 'address.additionnalDetails',
    label: 'property.form.additional_address_details',
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.postalCode,
  new ColumnDef({
    id: PropertyColumnEnum.postalCode,
    key: 'address.postalCode',
    label: 'property.form.postal_code',
    format: formatNumber,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.city,
  new ColumnDef({
    id: PropertyColumnEnum.city,
    key: 'address.city',
    label: 'property.form.city',
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.size,
  new ColumnDef({
    id: PropertyColumnEnum.size,
    key: 'size',
    label: 'property.form.total_size',
    format: formatSize,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.buyingPriceNet,
  new ColumnDef({
    id: PropertyColumnEnum.buyingPriceNet,
    key: 'buyingPrice.net',
    label: 'property.form.buying_price.net',
    format: formatMoney,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.buyingPriceAgencyFees,
  new ColumnDef({
    id: PropertyColumnEnum.buyingPriceAgencyFees,
    key: 'buyingPrice.agencyFees',
    label: 'property.form.buying_price.agency_fees',
    format: formatMoney,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.type,
  new ColumnDef({
    id: PropertyColumnEnum.type,
    key: 'type',
    label: 'property.form.type',
    i18nRootPath: 'property_type',
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.rentalAmount,
  new ColumnDef({
    id: PropertyColumnEnum.rentalAmount,
    key: 'rentalAmount',
    label: 'property.form.rent_amount',
    format: formatMoney,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.comment,
  new ColumnDef({
    id: PropertyColumnEnum.comment,
    key: 'comment',
    label: 'property.form.comment',
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.cutSize,
  new ColumnDef({
    id: PropertyColumnEnum.cutSize,
    root: 'details',
    preffix: 'property.form.cut',
    key: 'resalePrice.size',
    label: 'property.form.size',
    format: formatMoney,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.resalePriceLow,
  new ColumnDef({
    id: PropertyColumnEnum.resalePriceLow,
    root: 'details',
    preffix: 'property.form.cut',
    key: 'resalePrice.low.net',
    label: 'property.form.resale_price.low.net',
    format: formatMoney,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.resalePriceMedium,
  new ColumnDef({
    id: PropertyColumnEnum.resalePriceMedium,
    root: 'details',
    preffix: 'property.form.cut',
    key: 'resalePrice.medium.net',
    label: 'property.form.resale_price.medium.net',
    format: formatMoney,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.resalePriceHigh,
  new ColumnDef({
    id: PropertyColumnEnum.resalePriceHigh,
    root: 'details',
    preffix: 'property.form.cut',
    key: 'resalePrice.high.net',
    label: 'property.form.resale_price.high.net',
    format: formatMoney,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.detailsAgencyFeesLow,
  new ColumnDef({
    id: PropertyColumnEnum.detailsAgencyFeesLow,
    root: 'details',
    preffix: 'property.form.cut',
    key: 'resalePrice.low.agencyFees',
    label: 'property.form.resale_price.low.agency_fees',
    format: formatPercentage,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.detailsAgencyFeesMedium,
  new ColumnDef({
    id: PropertyColumnEnum.detailsAgencyFeesMedium,
    root: 'details',
    preffix: 'property.form.cut',
    key: 'resalePrice.medium.agencyFees',
    label: 'property.form.resale_price.medium.agency_fees',
    format: formatPercentage,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.detailsAgencyFeesHigh,
  new ColumnDef({
    id: PropertyColumnEnum.detailsAgencyFeesHigh,
    root: 'details',
    preffix: 'property.form.cut',
    key: 'resalePrice.high.agencyFees',
    label: 'property.form.resale_price.high.agency_fees',
    format: formatPercentage,
  })
);

propertyColumnsDef.set(
  PropertyColumnEnum.computedPriceLow,
  new ColumnDef({
    id: PropertyColumnEnum.computedPriceLow,
    root: 'details',
    preffix: 'property.form.cut',
    key: 'resalePrice.low.computedPrice',
    label: 'property.form.resale_price.low.computed_price',
    format: formatMoney,
  })
);

propertyColumnsDef.set(
  PropertyColumnEnum.computedPriceMedium,
  new ColumnDef({
    id: PropertyColumnEnum.computedPriceMedium,
    root: 'details',
    preffix: 'property.form.cut',
    key: 'resalePrice.medium.computedPrice',
    label: 'property.form.resale_price.medium.computed_price',
    format: formatMoney,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.computedPriceHigh,
  new ColumnDef({
    id: PropertyColumnEnum.computedPriceHigh,
    root: 'details',
    preffix: 'property.form.cut',
    key: 'resalePrice.high.computedPrice',
    label: 'property.form.resale_price.high.computed_price',
    format: formatMoney,
  })
);

propertyColumnsDef.set(
  PropertyColumnEnum.detailsSize,
  new ColumnDef({
    id: PropertyColumnEnum.detailsSize,
    root: 'details',
    preffix: 'property.form.cut',
    key: 'size',
    label: 'property.form.size',
    format: formatSize,
  })
);

propertyColumnsDef.set(
  PropertyColumnEnum.groundPriceNet,
  new ColumnDef({
    id: PropertyColumnEnum.groundPriceNet,
    key: 'ground.price.net',
    label: 'property.form.ground.price',
    format: formatMoney,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.groundAgencyFees,
  new ColumnDef({
    id: PropertyColumnEnum.groundAgencyFees,
    key: 'ground.price.agencyFees',
    label: 'property.form.ground.agency_fees',
    format: formatMoney,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.groundSize,
  new ColumnDef({
    id: PropertyColumnEnum.groundSize,
    key: 'ground.size',
    label: 'property.form.ground.size',
    format: formatSize,
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.serviced,
  new ColumnDef({
    id: PropertyColumnEnum.serviced,
    key: 'serviced',
    label: 'property.form.serviced',
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.sewage,
  new ColumnDef({
    id: PropertyColumnEnum.sewage,
    key: 'sewage',
    label: 'property.form.sewage',
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.individualElectricMeter,
  new ColumnDef({
    id: PropertyColumnEnum.individualElectricMeter,
    key: 'individualElectricMeter',
    label: 'property.form.individual_electric_meter',
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.createdBy,
  new ColumnDef({
    id: PropertyColumnEnum.createdBy,
    key: 'createdBy.fullName',
    label: 'property.created_by',
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.TRADER],
  })
);
propertyColumnsDef.set(
  PropertyColumnEnum.rentability,
  new ColumnDef({
    id: PropertyColumnEnum.rentability,
    key: 'rentability',
    label: 'property.rentability',
    roles: [UserRolesEnum.ADMIN, UserRolesEnum.TRADER],
    format: formatPercentage,
  })
);

export const propertyColumns = new Column(propertyColumnsDef);
export const propertyListColumns = propertyColumns.getColumns([
  PropertyColumnEnum.createdAt,
  PropertyColumnEnum.createdBy,
  PropertyColumnEnum.rentability,
  PropertyColumnEnum.type,
  PropertyColumnEnum.size,
  PropertyColumnEnum.city,
  PropertyColumnEnum.buyingPriceNet,
]);
export const propertyCsvColumns = propertyColumns.getColumns([]);
