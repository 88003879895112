<template>
  <list
    :columns="columns"
    :filter="filter"
    @updateLoading="updateLoading"
    :queryGetList="queryGetList"
    :queryDeleteElement="queryDeleteElement"
    :routes="routes"
    :mutationDeleteElement="mutationDeleteElement"
    :createButtonName="createButtonName"
  >
    <template #actions>
      <csv
        :queryGetList="queryGetList"
        :filter="filter"
        :readOnly="listLoading"
        :fields="csvFields"
        :csvName="$t('property.csv_name')"
      />
    </template>
  </list>
</template>

<script>
import Csv from '@/components/list/Csv';
import List from '@/components/list/List';
import { DELETE_PROPERTY, GET_PROPERTIES } from '@mdb/core';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { propertyCsvColumns, propertyListColumns } from './PropertyColumn';

export default defineComponent({
  components: { List, Csv },
  setup(props) {
    const filter = ref({});
    const listLoading = ref(false);
    const { t } = useI18n({ useScope: 'global' });

    const updateLoading = (value) => {
      listLoading.value = value;
    };

    const updateFilter = (value) => {
      filter.value = value;
    };

    return {
      columns: propertyListColumns,
      filter: filter,
      updateFilter: updateFilter,
      listLoading: listLoading,
      updateLoading: updateLoading,
      queryGetList: GET_PROPERTIES,
      mutationDeleteElement: DELETE_PROPERTY,
      routes: { create: 'create-property', read: 'read-property' },
      createButtonName: 'property.create_property',
      csvFields: propertyCsvColumns,
    };
  },
});
</script>
