<template>
  <va-button
    icon-right="file_download"
    color="secondary"
    :disabled="getCsvLoading || readOnly"
    @click="downloadCSVData"
    >{{ $t('property.export') }}</va-button
  >
</template>

<script>
import { defineComponent, ref, toRefs, watch } from 'vue';
import useGetList from '@/components/helpers/useGetList';
import { useQuery, useResult } from '@vue/apollo-composable';
import { Parser } from 'json2csv';
import { isEmpty } from 'lodash';
import { useI18n } from 'vue-i18n';

/**
 * Simple component with a button to downloard a csv.
 * Execute a query with filter and option, and return the result as a csv
 **/
export default defineComponent({
  props: {
    readOnly: { type: Boolean, required: false, default: true },
    filter: { type: Object },
    queryGetList: { type: Object, required: true },
    csvName: { type: String, required: true },
    fields: { type: Array, required: true }
  },
  setup(props) {
    const { filter } = toRefs(props);
    const queryOptions = ref({ enabled: false, notifyOnNetworkStatusChange: true });
    const csv = ref();
    const { t } = useI18n({ useScope: 'global' });
    //const fields = ref(props.fields.map(field => t(field.label)))

    const { items, loading: getCsvLoading, error, refetch: getCsv } = useGetList(
      null,
      null,
      filter.value,
      props.queryGetList,
      queryOptions
    );
    const json2csvParser = new Parser();

    watch(items, newItems => {
      const result = newItems.map(elt => {
        const csvLine = {};
        props.fields.forEach(field => {
          if (field.root) {
            for (let i = 0; i < elt[field.root].length; i++) {
              csvLine[`${t(field.preffix)} ${i + 1} \n ${t(field.label)}`] = field.value(elt, t, i);
            }
          } else {
            csvLine[t(field.label)] = field.value(elt, t);
          }
        });
        return csvLine;
      });
      console.error(result);
      csv.value = json2csvParser.parse(result);
    });
    // watch any change on the loading csv to dowloard the new csv. We don't check the csv object to allows the user to dowloard multiple time the csv
    watch([getCsvLoading], ([loading]) => {
      if (isEmpty(csv.value) || loading.value) {
        return;
      }
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv.value);
      anchor.target = '_blank';
      anchor.download = `${props.csvName}.csv`;
      anchor.click();
    });

    /**
     * Dowloard a CSV Data
     */
    const downloadCSVData = () => {
      // enable query the first time the user click
      if (!queryOptions.value.enabled) {
        queryOptions.value.enabled = true;
        return;
      }
      // refresh query
      getCsv();
    };

    return {
      downloadCSVData,
      getCsvLoading
    };
  }
});
</script>
